/**
 * @name 景点管理
 */
import request from '@/utils/request'

export const getAttractionsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/sites/setup'
  })
}

export const getAttractionsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/sites',
    params
  })
}

export const getAttractionsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/sites/${id}/preview`
  })
}

export const getAttractionsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/sites/${id}`
  })
}

export const getAttractionsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/sites/collections'
  })
}

export const deleteAttractionsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/sites/${id}`
  })
}

export const postAttractionsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/sites',
    data
  })
}

export const putAttractionsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/sites/${id}`,
    data
  })
}

export const updateAttractionsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/sites/${id}/online`
  })
}

export const updateAttractionsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/sites/${id}/offline`
  })
}
/**
* @name 门票管理
*/

export const getTicketsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/pack_infos/setup'
  })
}

export const getTicketsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/pack_infos',
    params
  })
}

export const getTicketsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/pack_infos/${id}/preview`
  })
}

export const getTicketsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/pack_infos/collections'
  })
}

export const getTicketsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/pack_infos/${id}`
  })
}

export const deleteTicketsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/pack_infos/${id}`
  })
}

export const postTicketsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/pack_infos',
    data
  })
}

export const putTicketsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/pack_infos/${id}`,
    data
  })
}
export const updateTicketsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/pack_infos/${id}/online`
  })
}

export const updateTicketsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/pack_infos/${id}/offline`
  })
}

/**
* @name 标签管理
*/

export const getSiteLabelsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/site_labels/setup'
  })
}

export const getSiteLabelsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/site_labels',
    params
  })
}

export const getSiteLabelsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/site_labels/${id}/preview`
  })
}

export const getSiteLabelsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/site_labels/collections'
  })
}

export const getSiteLabelsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/site_labels/${id}`
  })
}

export const deleteSiteLabelsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/site_labels/${id}`
  })
}

export const postSiteLabelsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/site_labels',
    data
  })
}

export const putSiteLabelsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/site_labels/${id}`,
    data
  })
}
