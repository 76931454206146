import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 24,
      prop: 'homepage_display',
      value: true,
      name: 'switch',
      label: i18n.t('form.basicMessage.homepageDisplay'),
      Object: {}
    }, {
      span: 8,
      prop: 'activity_name',
      name: 'input',
      label: i18n.t('form.activity.activityName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'activity_name_en',
      name: 'input',
      label: i18n.t('form.activity.activityNameEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      label: i18n.t('form.product.rankingPriority'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'activity_type',
      name: 'select',
      options: [],
      value: '',
      label: i18n.t('form.activity.activityType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'start_date',
      name: 'date',
      type: 'datetime',
      label: i18n.t('form.common.startDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        format: 'yyyy-MM-dd HH:mm',
        'value-format': 'yyyy-MM-dd HH:mm'
      }
    }, {
      span: 8,
      prop: 'end_date',
      name: 'date',
      type: 'datetime',
      label: i18n.t('form.common.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        format: 'yyyy-MM-dd HH:mm',
        'value-format': 'yyyy-MM-dd HH:mm'
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'remote_image_url'
      }
    }]
  }, {
    title: i18n.t('form.attractions.specialLabel'),
    name: 1,
    createDateItem: [{
      span: 24,
      prop: 'labels_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.attractions.label'),
      Object: {
        delete: '_destroy'
      },
      createDateFirstItem: [{
        span: 8,
        prop: 'start_date',
        name: 'date',
        type: 'datetime',
        label: i18n.t('form.common.startDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          format: 'yyyy-MM-dd HH:mm',
          'value-format': 'yyyy-MM-dd HH:mm'
        }
      }, {
        span: 8,
        prop: 'end_date',
        name: 'date',
        type: 'datetime',
        label: i18n.t('form.common.endDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          format: 'yyyy-MM-dd HH:mm',
          'value-format': 'yyyy-MM-dd HH:mm'
        }
      }]
    }]
  }]
}
