<template>
  <div>
    <el-card>
      <alter-form :watchImageVisible="false" @formSubmit="formSubmit" :formData="formData" :collapseLength="collapseLength"></alter-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
